import React from "react";

import {ShortInfoBar} from "../../atoms/ShortInfoBar";
import {OfferType} from "../../offer/types/OfferType";

interface IProps {
    number: string;
    area: number;
    rooms: number;
    floor: number;
    propertyType: number | null;
    className?: string;
}

export const PropertyInformation = (props: IProps) => {
    return (
        <ShortInfoBar
            infoItems={[
                {value: props.number, label: "Nieruchomość"},
                {
                    value: (
                        <>
                            {Math.round(props.area)} m<sup>2</sup>
                        </>
                    ),
                    label: "Powierzchnia",
                    blockValueTranslation: true
                },
                {value: props.rooms.toString(), label: "Pokoje"},
                {value: props.floor === 0 ? "Parter" : props.floor.toString(), label: props.propertyType === OfferType.HOUSE ? "Piętra" : "Piętro"}
            ]}
            className={props.className}
        />
    );
};
