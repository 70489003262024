import {Dispatch} from "redux";

import {patchRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiLink} from "@pg-mono/rp-api-routes";

import {IUserProfile} from "../ts/interfaces/IUserProfile";

const PATCH_USER_PREFERENCES = "profile/PATCH_USER_PREFERENCES";
export const patchUserPreferencesRequestType = createRequestActionTypes(PATCH_USER_PREFERENCES);

const patchUserPreferencesApiLink = apiLink.user.preferences({})(null);
export const patchUserPreferences = (data: Partial<Pick<IUserProfile, "max_price">>) => (dispatch: Dispatch) => {
    return patchRequest({}, patchUserPreferencesApiLink, data).then((profile: IUserProfile) => {
        dispatch({type: patchUserPreferencesRequestType.success, result: profile});

        return profile;
    });
};
