import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const GalleryOutlineIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M2.33 3.864a.267.267 0 1 0 .007.535.267.267 0 0 0-.006-.535Z" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.496 3.647a1.868 1.868 0 1 1 2.08 3.105 1.868 1.868 0 0 1-2.08-3.105Zm.292 2.67c.221.147.48.225.745.224H5.53a1.339 1.339 0 1 0-.74-.223Z"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9 2.8H7.617l-.8-.8H4.248l-.047.047-.75.754H1v5.33h8V2.8ZM1.527 7.605v-4.28h2.14l.8-.8h2.131l.273.277.528.527h1.074v4.276H1.527Z"
            />
        </SvgIcon>
    );
};
