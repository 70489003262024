import {IApplicationAdditionalQuestionTypes} from "../../../components/application_form/ApplicationFormContext";
import {newLine} from "../../../types/text_generator/TextGeneratorTypes";
import {hasSimilarAptsQuestion} from "../message_strategies/validators";

export type IQuestionListCase = "property" | "offer" | "vendorProperty" | "vendorOffer";

export type IGetQuestionMessages<TQuestions extends string> = (questionCase: IQuestionListCase) => {[key in TQuestions]: string};

const isProperQuestionMessage = <TQuestions extends string>(
    questionMessages: {[key in TQuestions]: string},
    questionName: string
): questionName is TQuestions => Object.prototype.hasOwnProperty.call(questionMessages, questionName);

const createQuestionIntoMessageTransform =
    <TQuestions extends string>(questionMessages: {[key in TQuestions]: string}) =>
    (questionName: IApplicationAdditionalQuestionTypes) => {
        return isProperQuestionMessage<TQuestions>(questionMessages, questionName) ? questionMessages[questionName] : "";
    };

export const transformQuestionListIntoMessage = <TQuestions extends string>(getQuestionMessages: IGetQuestionMessages<TQuestions>) => {
    return (selectedQuestions: IApplicationAdditionalQuestionTypes[], questionCase: IQuestionListCase) => {
        let questions: Partial<IApplicationAdditionalQuestionTypes>[] = selectedQuestions;

        if (hasSimilarAptsQuestion(selectedQuestions) && !["vendorOffer", "vendorProperty"].includes(questionCase)) {
            questions = questions.filter((question) => question !== "similarApts");
        }

        const transformQuestionIntoMessage = createQuestionIntoMessageTransform(getQuestionMessages(questionCase));

        return `${questions.map(transformQuestionIntoMessage).filter(Boolean).join(`,${newLine}`)}.${newLine}`;
    };
};
