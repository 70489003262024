import React from "react";
import {css, useTheme} from "@emotion/react";

import {pl} from "@pg-design/helpers-css";
import {CloseIcon} from "@pg-design/icons";
import {Image} from "@pg-design/image";
import {Text} from "@pg-design/text";

interface IProps {
    className?: string;
    vendor: {
        name: string;
        logo: {v_log_120x90: string};
    } | null;
    offer: {
        name: string;
    } | null;
    onCloseModal: (forceClose?: boolean) => void;
}

export const VendorDisplay = (props: IProps) => {
    const {className} = props;
    const {vendor, offer} = props;

    const theme = useTheme();

    if (vendor && offer) {
        return (
            <div css={vendorDisplayStyle} className={className}>
                <Image src={vendor.logo.v_log_120x90} alt={vendor.name} width="96px" height="64px" ratio={{xs: "96:64"}} css={imageStyle} />
                <div css={vendorOfferNameStyle}>
                    <Text variant="headline_6" strong className="bt">
                        {offer.name}
                    </Text>
                    <Text color={theme.colors.gray[700]} variant="body_copy_2" className="bt">
                        {vendor.name}
                    </Text>
                </div>

                <div css={closeHolder} onClick={() => props.onCloseModal(false)}>
                    <CloseIcon size="2.4" />
                </div>
            </div>
        );
    }

    return null;
};

const vendorDisplayStyle = css`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 6.4rem;
    max-height: 6.4rem;
`;

const imageStyle = css`
    flex: 0 0 9.6rem;
`;

const vendorOfferNameStyle = css`
    ${pl()};
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`;

const closeHolder = css`
    cursor: pointer;
    position: absolute;
    top: 2.4rem;
    right: 2.4rem;
`;
