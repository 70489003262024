import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const BrandCheckboxCheckedIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M2.017 2.211a.623.623 0 0 0-.198.044c-.098.084-.1.12-.1 2.874 0 2.627 0 2.639.049 2.67.041.028.547.037 2.592.06 1.399.012 2.62.017 2.712.004.162-.017.174-.022.22-.102.052-.084.052-.12.06-2.02l.006-1.935.238-.186c.13-.103.305-.237.389-.298.176-.13.308-.286.295-.348-.022-.115-.215-.039-.638.255-.147.1-.269.178-.271.176-.005-.005-.02-.252-.037-.546-.032-.609-.032-.606-.225-.648-.144-.031-4.828-.031-5.092 0Zm2.514.282c1.333.01 2.19.004 2.336-.01.127-.015.232-.022.232-.022 0 .002-.002.266-.007.584l-.007.578-.218.176c-.12.095-.389.357-.597.582-.638.69-.993 1.152-1.274 1.658l-.193.347-.294-.435c-.296-.438-.826-1.1-1.071-1.338-.156-.152-.58-.438-.68-.46-.09-.02-.161.003-.18.054-.025.064.004.113.124.203.494.367 1.492 1.565 1.854 2.226.164.303.217.366.305.366.093 0 .145-.068.326-.44.234-.484.704-1.208 1.125-1.741.149-.19.711-.753.75-.753.008 0 .015.792.015 1.76L7.075 7.59H1.976V2.45l.227.014c.122.008 1.171.022 2.328.03Z" />
        </SvgIcon>
    );
};
