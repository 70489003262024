import {IApplicationAdditionalFlatQuestionTypes} from "../../../../components/application_form/ApplicationFormContext";
import {transformQuestionListIntoMessage} from "../../text_utils/transform_question_list_into_message";

export const transformFlatQuestionListIntoMessage = transformQuestionListIntoMessage<IApplicationAdditionalFlatQuestionTypes>((detailCase) => {
    switch (detailCase) {
        case "property":
        case "vendorProperty":
            return {
                price: "Cena mieszkania",
                parkAndStorage: "Dostępność miejsc parkingowych i komórek lokatorskich wraz z ceną",
                rent: "Prognozowana wysokość czynszu",
                payPlan: "Harmonogram płatności",
                areaInfr: "Plan zagospodarowania przestrzennego",
                similarApts: "Podobne mieszkania w tej inwestycji"
            };

        case "vendorOffer":
            return {
                price: "Ceny mieszkań",
                parkAndStorage: "Dostępność miejsc parkingowych i komórek lokatorskich wraz z ceną",
                rent: "Prognozowana wysokość czynszu",
                payPlan: "Harmonogram płatności",
                areaInfr: "Plan zagospodarowania przestrzennego",
                similarApts: "Podobne mieszkania w tej inwestycji"
            };

        case "offer":
            return {
                price: "Zakresu cen mieszkań",
                parkAndStorage: "Dostępności miejsc parkingowych i komórek lokatorskich wraz z ceną",
                rent: "Prognozowanej wysokości czynszu",
                payPlan: "Harmonogramu płatności",
                areaInfr: "Planu zagospodarowania przestrzennego",
                similarApts: "Podobne mieszkania w tej inwestycji"
            };
    }

    throw new Error("You have to provide a valid 'detailCase'");
});
