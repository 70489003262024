import {IApplicationAdditionalHouseQuestionTypes} from "../../../../components/application_form/ApplicationFormContext";
import {transformQuestionListIntoMessage} from "../../text_utils/transform_question_list_into_message";

export const transformHouseQuestionListIntoMessage = transformQuestionListIntoMessage<IApplicationAdditionalHouseQuestionTypes>((detailCase) => {
    switch (detailCase) {
        case "property":
        case "vendorProperty":
            return {
                price: "Cena domu",
                heatingType: "Rodzaj ogrzewania",
                gardenSize: "Powierzchnia ogródka",
                payPlan: "Harmonogram płatności",
                areaInfr: "Plan zagospodarowania przestrzennego",
                similarApts: "Inne domy z Państwa oferty"
            };

        case "vendorOffer":
            return {
                price: "Zakres cen domów",
                payPlan: "Harmonogram płatności",
                areaInfr: "Plan zagospodarowania przestrzennego",
                heatingType: "Rodzaj ogrzewania",
                gardenSize: "",
                similarApts: ""
            };

        case "offer":
            return {
                price: "Zakresu Cen Domów",
                payPlan: "Harmonogramu płatności",
                areaInfr: "Planu zagospodarowania przestrzennego",
                heatingType: "Rodzaju ogrzewania",
                gardenSize: "",
                similarApts: ""
            };
    }

    throw new Error("You have to provide a valid 'detailCase'");
});
