import {IApplicationData} from "../../application/reducers/application_reducer";
import {ApplicationSource, ApplicationSourceSection} from "../../application/utils/ApplicationSource";
import {ViewType} from "../../view_type/ViewType";

/**
 * A helper that provides analytics data object, shared in algolytics tracking objects
 * @param leadData
 * @param viewType
 * @param source
 * @param sourceSection
 */
export function getLeadAnalyticsData(
    leadData: IApplicationData | undefined,
    viewType: ViewType | null,
    source: ApplicationSource,
    sourceSection: ApplicationSourceSection
) {
    const offer = leadData?.offer?.detail || null;

    const property = leadData?.property?.detail || null;

    return {
        source_id: source,
        source_section: sourceSection,
        view_type: viewType,
        ...(property ? {property_id: property.id} : {}),
        ...(offer ? {offer_id: offer.id} : {})
    };
}
