import {delayHit, hitAlgolytics} from "@pg-mono/algolytics";

import {MultiLeadRecommendationType} from "../../application/types/MultiLeadRecommendationType";
// we use this for unification process
import {ViewType} from "../../view_type/ViewType";
import {getTrackedSiteData} from "./tracked_utils/tracked_site";

export const modalCloseClickHit = (viewType: ViewType | null, modalName: string, recommendationType: MultiLeadRecommendationType) => {
    const payload = {
        ...getTrackedSiteData(),
        view_type: viewType,
        modal_name: modalName,
        recommendationType
    };
    modalCloseClickHitAlgolytics(payload);
};

export const modalCloseClickHitAlgolytics = delayHit((payload: Object) => hitAlgolytics("modal_close_click", payload), 500);
