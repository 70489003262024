import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const HotIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M8.105 6.438c.085-.04.167-.084.248-.131.026-.166.04-.333.04-.5V5.63a3.054 3.054 0 0 0-.04-.327 2.46 2.46 0 0 0-.03-.175l-.03-.123a3.159 3.159 0 0 0-.252-.665l-.007-.006a3.226 3.226 0 0 0-.736-.943 2.381 2.381 0 0 1 .014-1.9c.07-.141.155-.274.252-.398a2.834 2.834 0 0 0-.527-.088L7.004 1H6.888c-.126 0-.25.008-.374.023-.6.062-1.169.303-1.63.692a3.495 3.495 0 0 0-1.26 1.961c-.031.134-.054.27-.07.406a3.07 3.07 0 0 1-.152-.456 3.003 3.003 0 0 1-.07-.417 3.195 3.195 0 1 0 5.023 3.096 2.847 2.847 0 0 1-.25.133Zm-.775.93a2.646 2.646 0 0 1-4.294-3.094c.291.69.796 1.268 1.44 1.65a2.89 2.89 0 0 1-.391-1.459 2.908 2.908 0 0 1 .306-1.306l.03-.06a2.876 2.876 0 0 1 .582-.76c.409-.474 1.025-.745 1.701-.793v.01c-.089.279-.136.568-.14.86v.038A2.944 2.944 0 0 0 6.87 3.75a2.66 2.66 0 0 1 .974 2.055c0 .055 0 .11-.006.164v.057c-.037.487-.21.953-.5 1.346l-.008-.002Z" />
        </SvgIcon>
    );
};
