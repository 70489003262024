import {IApplicationFormValues} from "../../application/types/IApplicationFormValues";
import {getPhoneDataFromString} from "../../form/utils/get_phone_data_from_string";
import {ISentLeadStoredValues} from "./sent_lead_form_values";

export function getLeadFormValuesFromStorage(storagedLeadFormValues: ISentLeadStoredValues, defaultFormValues: IApplicationFormValues): IApplicationFormValues {
    const fieldsToBeRestored: (keyof ISentLeadStoredValues)[] = ["name", "email", "phone", "financing_services", "new_property_notification_consent"];

    const storageValues = fieldsToBeRestored.reduce<IApplicationFormValues>(
        (previousValue, currentValue) => {
            return {
                ...previousValue,
                [currentValue]: storagedLeadFormValues[currentValue]
            };
        },
        {...defaultFormValues}
    );

    storageValues.phone = getPhoneDataFromString(storagedLeadFormValues.phone);

    return storageValues;
}
