import {IMultiLeadData} from "../../application/types/IMultiLeadData";
import {MultiLeadRecommendationType} from "../../application/types/MultiLeadRecommendationType";
import {hitGoogleTagManager} from "./init_google_tag_manager";

interface IGTMMultileadEvent {
    event: string;
    recommendations_count?: number;
    recommended_properties?: IMultiLeadData[];
    is_checked?: boolean;
    property_id?: number;
}

export const gtmMultileadModalOpen = (recommendedItems: IMultiLeadData[], recommendationType: MultiLeadRecommendationType) => {
    const recommendationsCount = recommendedItems.length;
    const gtmData: IGTMMultileadEvent = {
        event: "multilead_view",
        recommendations_count: recommendationsCount,
        recommended_properties: recommendationType === MultiLeadRecommendationType.PROPERTY ? recommendedItems : undefined
    };
    hitGoogleTagManager(gtmData);
};

export const gtmMultileadModalCheckboxClick = (isChecked: boolean, propertyId: number) => {
    const gtmData: IGTMMultileadEvent = {
        event: "multilead_click",
        is_checked: isChecked,
        property_id: propertyId
    };

    hitGoogleTagManager(gtmData);
};

export const gtmMultileadModalClose = () => {
    hitGoogleTagManager({event: "multilead_close"});
};

export enum MultileadButtonType {
    SHOW_MORE_RECOMMENDATIONS = "zobacz więcej",
    SEND_APPLICATIONS = "wyślij zapytanie"
}

export const gtmMultileadPinClick = () => {
    hitGoogleTagManager({
        event: "multilead_pin_click"
    });
};

export const gtmMultileadButtonClick = (buttonType: MultileadButtonType, updateRecommendationCount?: number) => {
    hitGoogleTagManager({
        event: "multilead_button_click",
        button: buttonType,
        ...(updateRecommendationCount && {recommendations_count: updateRecommendationCount})
    });
};
