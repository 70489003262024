import {FinancingServicesCopyTypes} from "../types/FinancingServicesCopyTypes";

export function getFinancingServicesCopyText(copyType?: FinancingServicesCopyTypes) {
    let copy = "Poproszę o przedstawienie";

    switch (copyType) {
        case FinancingServicesCopyTypes.NONE_PROPERTY:
            copy = `${copy} oferty kredytu na zakup mieszkania.`;
            break;
        case FinancingServicesCopyTypes.SINGLE_PROPERTY:
            copy = `${copy} oferty kredytu na to mieszkanie.`;
            break;
        case FinancingServicesCopyTypes.MULTI_PROPERTY:
            copy = `${copy} ofert kredytu na te mieszkania.`;
            break;
        case FinancingServicesCopyTypes.MODULAR_HOUSE:
            copy = `${copy} oferty kredytu na zakup nieruchomości.`;
            break;
        default:
            copy = `${copy} oferty kredytu na zakup mieszkania inwestycyjnego.`;
    }

    return copy;
}
