type SimpleMessage = string;

export type MessageTextType = MessageVariantModel | SimpleMessage;

export interface MessageVariantModel {
    text: MessageTextType;
    separator?: MessageTextType;
    next?: MessageTextType;
}

export type DefaultContextType = Record<string, string>;

export type MessageStrategy<TContext extends {} = DefaultContextType> = (context: TContext) => MessageTextType;
export type MessageStrategyWithoutContext = () => MessageTextType;

export const newLine = "\n";
