import {css} from "@emotion/react";

import {m, mb} from "@pg-design/helpers-css";

import {MessageType} from "../../../types/MessageType";
import {ApplicationMessage} from "../fields/ApplicationMessage";
import {DefinedQuestions} from "../fields/DefinedQuestions";
import {MessageTypePicker} from "../fields/MessageTypePicker";
import {FieldLabel} from "./FieldLabel";

interface IProps {
    showMessageOnly: boolean;
    messageType: MessageType;
}
export const MessageAndQuestionsSection = (props: IProps) => {
    if (props.showMessageOnly) {
        return (
            <>
                <FieldLabel>Twoje zapytanie</FieldLabel>
                <ApplicationMessage css={mb(3)} />
            </>
        );
    }
    return (
        <>
            <FieldLabel>Twoje zapytanie</FieldLabel>
            <MessageTypePicker css={mb(2)} />
            {props.messageType === MessageType.GENERATED && <DefinedQuestions css={definedQuestionsStyle} />}
            {props.messageType === MessageType.CUSTOM && <ApplicationMessage css={mb(3)} />}
        </>
    );
};

const definedQuestionsStyle = css`
    ${m(0, 0, 4)};
`;
