import React from "react";
import {css} from "@emotion/react";

import {elevation} from "@pg-design/elevation";
import {areaFormat, pluralize} from "@pg-mono/string-utils";

import {getOfferTypeNameCapital} from "../../../../../offer/helpers/OfferType";
import {OfferType} from "../../../../../offer/types/OfferType";
import {ApplicationOfferBoxBase} from "./ApplicationOfferBoxBase";

interface IProps {
    offerAddress: string;
    offerMainImg?: string;
    offerType: OfferType;
    rooms?: number;
    area?: number;
    offerVendorName: string;
    offerVendorLogo?: string;
    className?: string;
}

export const ApplicationOfferSentSummaryBox = (props: IProps) => {
    const displayType = getOfferTypeNameCapital(props.offerType);

    return (
        <ApplicationOfferBoxBase
            title={`${displayType}, ${props.rooms ? `${props.rooms} ${roomsPluralize(props.rooms)}, ` : ""}${props.area ? areaFormat(props.area) : ""}`}
            address={props.offerAddress}
            imgSrc={props.offerMainImg}
            css={boxWrapperStyle}
            className={props.className}
            content={
                <div css={contentWrapperStyle}>
                    <ApplicationOfferBoxBase.Logo alt={props.offerVendorName} imgSrc={props.offerVendorLogo} />
                </div>
            }
        />
    );
};

const roomsPluralize = pluralize(["pokój", "pokoje", "pokoi"]);

const boxWrapperStyle = css`
    ${elevation(4)};
    min-height: 13rem;
`;

const contentWrapperStyle = css`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
`;
