import {OfferType} from "../../../offer/types/OfferType";
import {Country} from "../../../region/types/Country";
import {IApplicationAdditionalQuestionTypes} from "../../components/application_form/ApplicationFormContext";
import {newLine} from "../../types/text_generator/TextGeneratorTypes";
import {ApplicationSource} from "../ApplicationSource";
import {transformCommercialQuestionListIntoMessage} from "./message_strategies/commercial/transform_commercial_question_list_into_message";
import {transformFlatQuestionListIntoMessage} from "./message_strategies/flat/transform_flat_question_list_into_message";
import {transformHouseQuestionListIntoMessage} from "./message_strategies/house/transform_house_question_list_into_message";
import {getFirstTruthy} from "./text_utils/get_first_truthy";
import {joinEveryOf} from "./text_utils/join_every_of";
import {getGeneratedMessageForCommercial} from "./get_generated_message_for_commercial";
import {getGeneratedMessageForFlat} from "./get_generated_message_for_flat";
import {getGeneratedMessageForHouse} from "./get_generated_message_for_house";

interface Context {
    offerType: OfferType;
    offerName: string;
    propertyNumber?: string;
    propertyPrice?: number | null;
    selectedRooms: number[];
    selectedQuestions: IApplicationAdditionalQuestionTypes[];
    source: ApplicationSource;
    country?: Country;
}

export const getGeneratedMessage = (context: Context) => {
    switch (context.offerType) {
        case OfferType.COMMERCIAL:
            return getGeneratedMessageForCommercial(context);
        case OfferType.HOUSE:
            return getGeneratedMessageForHouse(context);
        default:
            return getGeneratedMessageForFlat(context);
    }
};

//  Figma: Co widzi deweloper
export function getVendorMessage(
    offerType: OfferType | undefined,
    clientMessage: string,
    questions: IApplicationAdditionalQuestionTypes[],
    clientName: string,
    offerName: string,
    forProperty?: boolean
) {
    if (questions.length === 0) {
        return clientMessage;
    }

    const contentSeparator = `${newLine}${newLine}`;

    const transformQuestionListIntoMessage = () => {
        switch (offerType) {
            case OfferType.HOUSE:
                return transformHouseQuestionListIntoMessage;
            case OfferType.COMMERCIAL:
                return transformCommercialQuestionListIntoMessage;
            case OfferType.FLAT:
            default:
                return transformFlatQuestionListIntoMessage;
        }
    };

    return joinEveryOf([
        getFirstTruthy([
            () => !!forProperty && `Klienta ${clientName} interesuje:`,
            () => `Klienta ${clientName} interesują nieruchomości w inwestycji ${offerName} a konkretnie:`
        ]),
        contentSeparator,
        transformQuestionListIntoMessage()(questions, forProperty ? "vendorProperty" : "vendorOffer"),
        contentSeparator,
        "Poniżej pełna wiadomość",
        contentSeparator,
        clientMessage
    ]);
}
