import React from "react";
import {css} from "@emotion/react";
import * as Yup from "yup";

import {FormikForm, IFormikSubmitFn} from "@pg-design/formik-utils";

import {validationMessages} from "../../../form/utils/validation_messages";
import {useEmailVerification} from "../../hooks/use_email_verification";
import {IApplicationAnalyticsData} from "../../tracking/tracking";
import {ApplicationVariant} from "../../types/ApplicationVariant";
import {IApplicationFormValues} from "../../types/IApplicationFormValues";
import {ApplicationSource, ApplicationSourceSection} from "../../utils/ApplicationSource";
import {ApplicationFormBody} from "./ApplicationFormBody";
import {ApplicationFormContextProvider, IApplicationMessageLogic} from "./ApplicationFormContext";

interface IProps {
    // formik
    initialValues: IApplicationFormValues;
    onSubmit: IFormikSubmitFn<IApplicationFormValues>;
    onChange?: (formValues: IApplicationFormValues) => void;

    // tracking
    getAnalyticsData: () => IApplicationAnalyticsData; // analytics data is stored in context because it is needed not only on submit - hits are also triggered on form elements interaction.
    source: ApplicationSource;
    sourceSection: ApplicationSourceSection;

    // form field visibility
    alwaysShowFields?: boolean;
    enableLegalDetailsHiding: boolean; // allow to hide legal agreements and legal-details-link (user is logged in)
    hideFinancingServices?: boolean; // do not display checkbox (Onet forms do not display it)
    hideMessageSection: boolean; // do not display message section (some forms are without it)
    hidePropertyNotification?: boolean; // do not display checkbox (Landing page forms do not display it)
    hideQuestions?: boolean;
    hideRodo?: boolean;
    hideRooms?: boolean; // do not display rooms checkboxes (property forms do not display it)
    hideSubmitButton?: boolean;
    offerId?: number;

    // rest
    applicationMessageLogic: IApplicationMessageLogic;
    className?: string;
    formPreviouslySent: boolean; // we found LS-data, so form was previously sent
    onFormControlClick?: () => void; // callback for every form control click (previously described as `for every interaction`, but it was only called on click events - possible error?)
    storeHash: string;
    closeModal: () => void;
    submitOnMount?: boolean;
    applicationVariant?: ApplicationVariant;

    // post
    leadIsSubmitting: boolean;
    customFormText?: string;
}

const applicationSchema = Yup.object().shape({
    email: Yup.string().email().required(validationMessages.email),
    name: Yup.string().required(validationMessages.required),
    phone: Yup.object({
        code: Yup.number().required(validationMessages.required),
        number: Yup.string().required(validationMessages.required)
    })
});

export const ApplicationForm = (props: IProps) => {
    const {hideRooms = false, hideFinancingServices = false, hidePropertyNotification = false, submitOnMount} = props;
    const {props: emailNotificationProps, validateEmailOnce} = useEmailVerification();

    const onSubmit: IFormikSubmitFn<IApplicationFormValues> = async (values, formikHelpers) => {
        if (!(await validateEmailOnce(values?.email))) {
            return;
        }

        return props.onSubmit(values, formikHelpers);
    };

    return (
        <FormikForm onSubmit={onSubmit} onChange={props.onChange} initialValues={props.initialValues} enableReinitialize validationSchema={applicationSchema}>
            <div css={scrollableForm}>
                <ApplicationFormContextProvider
                    leadIsSubmitting={props.leadIsSubmitting}
                    storeHash={props.storeHash}
                    emailNotificationProps={emailNotificationProps}
                    formPreviouslySent={props.formPreviouslySent}
                    onFormControlClick={props.onFormControlClick}
                    hideRooms={hideRooms}
                    hidePropertyNotification={hidePropertyNotification}
                    enableLegalDetailsHiding={props.applicationVariant === ApplicationVariant.CREDIT || props.enableLegalDetailsHiding}
                    hideFinancingServices={props.applicationVariant === ApplicationVariant.CREDIT || hideFinancingServices}
                    hideMessageAndQuestionsSection={props.hideMessageSection}
                    hideSubmitButton={props.hideSubmitButton}
                    source={props.source}
                    offerId={props.offerId}
                    applicationMessageLogic={props.applicationMessageLogic}
                    sourceSection={props.sourceSection}
                    isAuthenticated={props.enableLegalDetailsHiding}
                    hideRodo={props.hideRodo}
                    getAnalyticsData={props.getAnalyticsData}
                    hideQuestions={props.hideQuestions}
                    alwaysShowFields={props.alwaysShowFields}
                    onCloseModal={props.closeModal}
                    customFormText={props.customFormText}
                >
                    <ApplicationFormBody submitOnMount={submitOnMount} applicationVariant={props.applicationVariant} />
                </ApplicationFormContextProvider>
            </div>
        </FormikForm>
    );
};

const scrollableForm = css`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;
