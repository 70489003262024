import {IApplicationOffer} from "../../../types/IApplicationOffer";
import {IApplicationVendor} from "../../../types/IApplicationVendor";
import {RodoFormInfo} from "../../RodoFormInfo";

interface IRodoTermsProps {
    offer: IApplicationOffer | null;
    vendor?: IApplicationVendor | null;
}

export const ApplicationFormRodoTerms = (props: IRodoTermsProps) => {
    const {offer, vendor} = props;

    return (
        <RodoFormInfo
            vendor={vendor}
            offerPrivacyPolicyUrl={offer?.configuration.privacy_policy_url}
            offerPersonalDataProcessorName={offer?.configuration.personal_data_processor_name}
            offerPersonalDataProcessorUrl={offer?.configuration.personal_data_processor_url}
        />
    );
};
