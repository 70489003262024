import React from "react";
import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";

import {Checkbox} from "@pg-design/checkbox";
import {borderRadius, h100, mb, p, pointer, pt, w100} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text";

import {OfferType} from "../../../../offer/types/OfferType";
import {insertIf} from "../../../../utils/insert_if";
import {questionClickHit} from "../../../tracking/tracking";
import {IApplicationAdditionalQuestionTypes, useApplicationFormContext} from "../ApplicationFormContext";

interface IProps {
    className?: string;
}

type IDefinedQuestion = {
    shortName: IApplicationAdditionalQuestionTypes;
    questionText: string;
};

interface IGetDefinedQuestionsConfigurationParams {
    offerType?: OfferType;
    isProperty?: boolean;
    hasPriceDefined?: boolean;
}

const getDefinedQuestionsConfiguration = ({
    offerType,
    isProperty = false,
    hasPriceDefined = false
}: IGetDefinedQuestionsConfigurationParams): IDefinedQuestion[] => {
    // Hide price when we have defined a price for property or price range for offer
    const shouldShowPriceOption = !hasPriceDefined;

    switch (offerType) {
        case OfferType.HOUSE:
            return [
                ...insertIf<IDefinedQuestion>(shouldShowPriceOption, {
                    shortName: "price",
                    questionText: isProperty ? "Cena domu" : "Zakres cen domów"
                }),
                ...insertIf<IDefinedQuestion>(
                    isProperty,
                    {
                        shortName: "heatingType",
                        questionText: "Rodzaj ogrzewania"
                    },
                    {
                        shortName: "gardenSize",
                        questionText: "Powierzchnia ogródka"
                    }
                ),
                {
                    shortName: "payPlan",
                    questionText: "Harmonogram płatności"
                },
                {
                    shortName: "areaInfr",
                    questionText: "Plan zagospodarowania przestrzennego"
                },
                ...insertIf<IDefinedQuestion>(!isProperty, {
                    shortName: "heatingType",
                    questionText: "Rodzaj ogrzewania"
                }),
                ...insertIf<IDefinedQuestion>(isProperty, {
                    shortName: "similarApts",
                    questionText: "Interesują mnie podobne domy w tej inwestycji"
                })
            ];
        default:
            return [
                ...insertIf<IDefinedQuestion>(shouldShowPriceOption, {
                    shortName: "price",
                    questionText: isProperty ? "Cenę tego mieszkania" : "Ceny mieszkań w tej inwestycji"
                }),
                {
                    shortName: "parkAndStorage",
                    questionText: "Dostępność miejsc parkingowych i komórek lokatorskich wraz z ceną"
                },
                {
                    shortName: "rent",
                    questionText: "Prognozowana wysokość czynszu"
                },
                {
                    shortName: "payPlan",
                    questionText: "Harmonogram płatności"
                },
                {
                    shortName: "areaInfr",
                    questionText: "Plan zagospodarowania przestrzennego"
                },
                ...insertIf<IDefinedQuestion>(isProperty, {
                    shortName: "similarApts",
                    questionText: "Interesują mnie podobne mieszkania w tej inwestycji"
                })
            ];
    }
};

export const DefinedQuestions = (props: IProps) => {
    const {className} = props;

    const {
        offer,
        property,
        applicationMessageLogic: {
            currentState: {selectedQuestions},
            helpers: {setSelectedQuestions}
        },
        analyticsData
    } = useApplicationFormContext();

    const isProperty = !!property;

    const definedQuestions = getDefinedQuestionsConfiguration({
        offerType: offer?.type,
        isProperty,
        hasPriceDefined: isProperty ? !!(property.price || property.price_m2) : !!offer?.stats?.ranges_price_min && !!offer?.stats?.ranges_price_max
    });

    const onOptionSelect = (name: string) => {
        const shortName = name as IApplicationAdditionalQuestionTypes;
        const questionAlreadySelected = selectedQuestions.includes(shortName);

        if (questionAlreadySelected) {
            const newQuestions = selectedQuestions.filter((selectedShortName) => selectedShortName !== shortName);
            setSelectedQuestions(newQuestions);
        } else {
            const newQuestions = [...selectedQuestions, shortName];
            setSelectedQuestions(newQuestions);
        }
    };

    const afterOptionChange = (name: string, checked: boolean) => {
        questionClickHit(name as IApplicationAdditionalQuestionTypes, checked, analyticsData);
    };

    return (
        <div css={questionChoicesHolder} className={className}>
            {definedQuestions.map((question) => {
                const {questionText, shortName} = question;

                const isChecked = selectedQuestions.includes(shortName);
                return (
                    <CheckboxWrapper key={shortName} isChecked={isChecked} readOnly={false}>
                        <Checkbox
                            id={shortName}
                            labelContent={<Text variant="info_txt_1">{questionText}</Text>}
                            name={shortName}
                            checked={isChecked}
                            value={shortName}
                            onChange={onOptionSelect}
                            onAfterChange={afterOptionChange}
                            css={checkboxStyle}
                            checkedMarkColor="#02D054"
                            size="lg"
                        />
                    </CheckboxWrapper>
                );
            })}
        </div>
    );
};

const questionChoicesHolder = (theme: Theme) => css`
    ${mb(3)};
    display: flex;
    flex-direction: column;
    background: #fff;
    ${borderRadius()};
    row-gap: 1.6rem;
    ${p(2)};

    @media (min-width: ${theme.breakpoints.md}) {
        ${p()};
    }

    @media (max-height: 740px) and (min-width: ${theme.breakpoints.md}) {
        row-gap: 0.8rem;
        ${mb(1)};
    }

    @media (max-height: 650px) and (min-width: ${theme.breakpoints.md}) {
        row-gap: unset;
    }
`;

const CheckboxWrapper = styled.div<{isChecked: boolean; readOnly: boolean}>`
    ${borderRadius(1)};
    ${mb()};
    ${pt()};
    ${(props) => !props.readOnly && pointer};
    display: flex;
    align-items: center;
`;

const checkboxStyle = css`
    ${w100};
    ${h100};

    width: 2.4rem;
    min-width: 2.4rem;
    height: 2.4rem;
`;
