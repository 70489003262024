import React from "react";
import {css} from "@emotion/react";

import {mb} from "@pg-design/helpers-css";
import {useIsMobile} from "@pg-mono/hooks";

import {FinancingServicesCopyTypes} from "../../../../lead/types/FinancingServicesCopyTypes";
import {useApplicationFormContext} from "../ApplicationFormContext";
import {FinancingServicesField} from "./FinancingServicesField";
import {NotificationConsentField} from "./NotificationConsentField";
import {RentalPriceField} from "./RentalPriceField";

interface IProps {
    onFormFieldClick?: () => void;
    showFinancingServicesField: boolean;
    showPropertyNotificationField: boolean;
    showPropertyRentalPriceField: boolean;
    copyType: FinancingServicesCopyTypes;
}

export const OptIns = (props: IProps) => {
    const {onFormFieldClick, showFinancingServicesField, showPropertyNotificationField, copyType} = props;
    const {hidePropertyNotification, enableLegalDetailsHiding, hideFinancingServices} = useApplicationFormContext();
    const isMobile = useIsMobile();

    return (
        <div>
            {!hidePropertyNotification && showPropertyNotificationField && (
                <NotificationConsentField name="new_property_notification_consent" onFieldClick={onFormFieldClick} css={mb(2)} />
            )}

            {(!enableLegalDetailsHiding || !hideFinancingServices || showFinancingServicesField) && (
                <FinancingServicesField
                    name="financing_services"
                    css={financingServicesCheckboxStyle}
                    popoverPlace={isMobile ? "top" : "left"}
                    copyType={copyType}
                />
            )}

            {props.showPropertyRentalPriceField && <RentalPriceField name="investment_services" css={mb(2)} />}
        </div>
    );
};

const financingServicesCheckboxStyle = css`
    ${mb(2)}
    position: relative;
    align-self: baseline;
`;
