import {MessageTextType} from "../../../types/text_generator/TextGeneratorTypes";

export const getMessageByModel = (messageStrategy: MessageTextType | undefined): string => {
    if (!messageStrategy) {
        return "";
    }

    if (typeof messageStrategy === "string") {
        return messageStrategy;
    }

    return `${getMessageByModel(messageStrategy.text)}${getMessageByModel(messageStrategy?.separator)}${getMessageByModel(messageStrategy?.next)}`;
};
