import React from "react";

import {FormikForm} from "@pg-design/formik-utils";
import {Popover} from "@pg-design/notifications";
import {Text} from "@pg-design/text";
import {useUserDevice} from "@pg-mono/user-device";

import {FinancingServicesCheckboxPopoverText} from "../../../../lead/components/FinancingServicesCheckboxPopoverText";
import {FinancingServicesCopyTypes} from "../../../../lead/types/FinancingServicesCopyTypes";
import {getFinancingServicesCopyText} from "../../../../lead/utils/get_financing_services_copy_text";

interface IProps {
    name: string;
    copyType?: FinancingServicesCopyTypes;
    className?: string;
    popoverPlace?: "top" | "right" | "bottom" | "left";
}

export const FinancingServicesField = (props: IProps) => {
    const {name, copyType, popoverPlace} = props;
    const {isMobile} = useUserDevice();

    return (
        <div className={props.className}>
            <Popover
                isActive
                popoverPlace={popoverPlace ? popoverPlace : isMobile ? "top" : "right"}
                body={<FinancingServicesCheckboxPopoverText />}
                toggleFocusHandler
                toggleOnClickOutside
                appendTo="parent"
                zIndex={10001}
            >
                <FormikForm.Checkbox
                    name={name}
                    id="financingServicesConsentField"
                    labelContent={
                        <Text as="span" variant="info_txt_1">
                            {getFinancingServicesCopyText(copyType)}
                        </Text>
                    }
                    checkedMarkColor="#02D054"
                />
            </Popover>
        </div>
    );
};
