import {MessageTextType} from "../../../types/text_generator/TextGeneratorTypes";
import {getMessageByModel} from "./get_message_by_model";

export const getFirstTruthy = (elements: (() => MessageTextType | false)[]): MessageTextType => {
    // for ... of loop, because textFn need to be called once. It is necessary for determining which path has been chosen, especially for unit tests.
    for (const textFn of elements) {
        const result = textFn();

        if (result) {
            return getMessageByModel(result);
        }
    }

    return "";
};
