import {IApplicationFormValues} from "../../application/types/IApplicationFormValues";
import {getPhoneString} from "../../form/utils/get_phone_string";

export function getParsedLeadFormValues(leadFormValues: IApplicationFormValues) {
    return {
        ...leadFormValues,
        phone: getPhoneString(leadFormValues.phone),
        financing_services: Boolean(leadFormValues.financing_services),
        new_property_notification_consent: Boolean(leadFormValues.new_property_notification_consent)
    };
}
