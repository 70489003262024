import {IBreakpointSettings} from "../components/SliderGallery";

/*
 * Returns the closest min-width matching breakpoint (mobile first)
 */

export const getClosestBreakpoint = (breakpointSettings: IBreakpointSettings[], windowWidth: number) => {
    let curr = breakpointSettings[0]; // 1024
    for (const el of breakpointSettings) {
        if (windowWidth > el.breakpoint && Math.abs(windowWidth - el.breakpoint) < Math.abs(windowWidth - curr.breakpoint)) {
            curr = el;
        }
    }
    return curr;
};
