export interface ITrackedVendor {
    id: number;
    name: string;
    slug?: string;
}

export const getTrackedVendorData = (vendor: ITrackedVendor) => ({
    vendor_id: vendor.id,
    vendor_name: vendor.name
});
