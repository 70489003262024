import {useCallback, useMemo} from "react";

import * as userLastSeenPropertiesUtils from "../utils/user_last_seen_offer_properties";
import {LastSeenOfferPropertiesOrderType} from "../utils/user_last_seen_offer_properties";

export const useUserLastSeenProperties = (offerIdsToExclude?: number[], orderType?: LastSeenOfferPropertiesOrderType) => {
    const getLastSeenProperties = useCallback(() => userLastSeenPropertiesUtils.getLastSeenOfferProperties(offerIdsToExclude, orderType), [orderType]);

    const {lastSeenProperties, lastSeenPropertyIds} = useMemo(() => {
        const lastSeenProperties = getLastSeenProperties();

        return {
            lastSeenProperties,
            lastSeenPropertyIds: lastSeenProperties.map((lastSeenProperty) => lastSeenProperty.id)
        };
    }, []);

    const addLastSeenProperty = useCallback(userLastSeenPropertiesUtils.addLastSeenOfferProperty, []);

    const removeLastSeenProperties = useCallback(userLastSeenPropertiesUtils.removeLastSeenOfferProperties, []);

    return {lastSeenProperties, lastSeenPropertyIds, getLastSeenProperties, addLastSeenProperty, removeLastSeenProperties};
};
