import React, {useCallback, useEffect, useMemo, useState} from "react";
import {css, Theme} from "@emotion/react";
import {useFormikContext} from "formik";

import {AdvancedScroll} from "@pg-design/advanced-scroll";
import {Button} from "@pg-design/button";
import {Expandable} from "@pg-design/expandable";
import {borderRadius, calculateRemSize, flex, flexAbsoluteCenter, m, mb, mt, onDesktop, p, pb, ph, pl} from "@pg-design/helpers-css";
import {ChevronDownIcon, ChevronUpIcon, CloseIcon} from "@pg-design/icons";
import {Loader} from "@pg-design/loader";
import {Text} from "@pg-design/text";
import {useUserDevice} from "@pg-mono/user-device";

import {FinancingServicesCopyTypes} from "../../../../lead/types/FinancingServicesCopyTypes";
import {useLastSeenPropertiesDetails} from "../../../../property/hooks/use_last_seen_properties_details";
import {removeLastSeenPropertiesByOfferIds} from "../../../../property/utils/user_last_seen_offer_properties";
import {LastSeenPropertiesGTMEventAction, lastSeenPropertiesLeadAnalytics} from "../../../tracking/last_seen_properties_lead_tracking";
import {IApplicationFormValues} from "../../../types/IApplicationFormValues";
import {MessageType} from "../../../types/MessageType";
import {getStorageSentApplicationsList} from "../../../utils/manage_storage_sent_applications";
import {TermsAcceptation} from "../../TermsAcceptation";
import {ApplicationOfferSelectionHeader} from "../application_offer/ApplicationOfferSelectionHeader";
import {ApplicationOfferBigBox} from "../application_offer/offer_box/ApplicationOfferBigBox";
import {ApplicationOfferSelectionBox} from "../application_offer/offer_box/ApplicationOfferSelectionBox";
import {ApplicationOfferSentSummaryBox} from "../application_offer/offer_box/ApplicationOfferSentSummaryBox";
import {useApplicationFormContext} from "../ApplicationFormContext";
import {OptIns} from "../fields/OptIns";
import {YourData} from "../fields/YourData";
import {ApplicationFormRodoTerms} from "./ApplicationFormRodoTerms";
import {SubmitButton} from "./SubmitButton";

interface IProps {
    showEmailField: boolean;
    showPhoneField: boolean;
    showNameField: boolean;
    showAllInputFields: () => void;
    messageType: MessageType;
    showTextAreaOnly: boolean;
    showPropertyNotificationField: boolean;
    showFinancingServicesField: boolean;
    showRentalPriceField: boolean;
}

const LAST_SEEN_PROPERTIES_LIMIT = 6;
export const ApplicationRetargetingForm = (props: IProps) => {
    const {offer, property, vendor, hideSubmitButton, isAuthenticated, formPreviouslySent, onCloseModal, leadIsSubmitting} = useApplicationFormContext();
    const {setFieldValue} = useFormikContext<IApplicationFormValues>();
    const {isMobile} = useUserDevice();
    const sentApplicationIds = useMemo(() => getStorageSentApplicationsList(offer?.region.country).map((sentApplication) => sentApplication.id), [offer]);
    const {
        propertyDetailList,
        propertiesCount,
        isLoading: isPropertiesDetailsLoading
    } = useLastSeenPropertiesDetails({excludeOfferIds: sentApplicationIds, limit: LAST_SEEN_PROPERTIES_LIMIT});
    const [selectedPropertyIds, setSelectedPropertyIds] = useState<number[]>([]);
    const togglePropertySelection = useCallback((propertyId: number) => {
        setSelectedPropertyIds((prevSelectedPropertyIds) => {
            if (prevSelectedPropertyIds.includes(propertyId)) {
                lastSeenPropertiesLeadAnalytics.gtm.lastSeenPropertiesLeadEvent(LastSeenPropertiesGTMEventAction.UNCHECK);
                return prevSelectedPropertyIds.filter((id) => id !== propertyId);
            }

            lastSeenPropertiesLeadAnalytics.gtm.lastSeenPropertiesLeadEvent(LastSeenPropertiesGTMEventAction.CHECK);
            return [...prevSelectedPropertyIds, propertyId];
        });
    }, []);

    useEffect(() => {
        // remove offers which user already sent application for
        removeLastSeenPropertiesByOfferIds(sentApplicationIds);
    }, [sentApplicationIds.join(",")]);

    useEffect(() => {
        setFieldValue("multiLeadProperties", selectedPropertyIds);
    }, [selectedPropertyIds]);

    useEffect(() => {
        if (!isPropertiesDetailsLoading && propertyDetailList.length > 0) {
            lastSeenPropertiesLeadAnalytics.gtm.lastSeenPropertiesLeadEvent(LastSeenPropertiesGTMEventAction.OFFER_VIEW);
        }
    }, [propertyDetailList, isPropertiesDetailsLoading]);

    useEffect(() => {
        if (propertyDetailList.length > 0) {
            setSelectedPropertyIds(propertyDetailList.map((propertyDetail) => propertyDetail.id));
        }
    }, [propertyDetailList]);

    return (
        <div css={wrapperStyle}>
            <div css={headerStyle}>
                <Text variant="headline_4">Zapytaj o ofertę</Text>

                <div css={closeIconWrapperStyle} onClick={() => onCloseModal()}>
                    <CloseIcon size="2.4" wrapperSize="2.4" />
                </div>
            </div>
            <div css={contentStyle}>
                {!isPropertiesDetailsLoading && propertiesCount === 0 ? (
                    offer && (
                        <ApplicationOfferBigBox
                            offerType={offer.type}
                            offerAddress={offer.address}
                            offerMainImg={offer.main_image.m_img_1500}
                            offerVendorLogo={vendor?.logo.v_log_80x60}
                            rooms={property?.rooms}
                            area={property?.area}
                            offerVendorName={vendor?.name || ""}
                            css={[mb(3), onDesktop(m(0, 3, 3, 3))]}
                        />
                    )
                ) : (
                    <AdvancedScroll
                        height={550}
                        css={offersColumnStyle}
                        contentStyle={!isMobile ? offersColumnContentStyle : undefined}
                        scrollTopShadowStyle={contentTopShadow}
                        scrollBottomShadowStyle={contentBottomShadow}
                        disableScroll={!!isMobile || propertyDetailList.length < 2}
                    >
                        {!isMobile && propertyDetailList.length >= 2 && <div css={[applicationOfferSentSummaryBoxMaskStyle, contentTopShadow]} />}
                        {offer && (
                            <ApplicationOfferSentSummaryBox
                                offerType={offer.type}
                                offerAddress={offer.address}
                                offerMainImg={offer.main_image.m_img_375x211}
                                offerVendorLogo={vendor?.logo.v_log_80x60}
                                rooms={property?.rooms}
                                area={property?.area}
                                offerVendorName={vendor?.name || ""}
                                css={mb(2)}
                            />
                        )}

                        <Expandable
                            isAlwaysExpanded={!isMobile}
                            isExpandedByDefault={!!isMobile}
                            collapsedHeight={160}
                            customCollapseButton={({isExpanded, toggleCollapse}) => (
                                <Button
                                    variant="filled_secondary"
                                    size="x-small"
                                    type="button"
                                    iconRight={isExpanded ? ChevronUpIcon : ChevronDownIcon}
                                    onClick={toggleCollapse}
                                    css={[expandableButtonStyle, ph(3)]}
                                >
                                    {isExpanded ? "Zwiń oferty" : "Rozwiń oferty"}
                                </Button>
                            )}
                        >
                            <div css={retargetedOffersWrapperStyle}>
                                <ApplicationOfferSelectionHeader />

                                <div css={retargetedOfferListStyle}>
                                    {isPropertiesDetailsLoading ? (
                                        <Loader size="lg" fullWidth />
                                    ) : (
                                        <>
                                            {propertyDetailList.map((propertyDetail) => (
                                                <ApplicationOfferSelectionBox
                                                    key={propertyDetail.id}
                                                    propertyId={propertyDetail.id}
                                                    offerType={propertyDetail.offer.type}
                                                    offerAddress={propertyDetail.offer.address}
                                                    offerMainImg={propertyDetail.offer.main_image.m_img_375x211}
                                                    rooms={propertyDetail.rooms}
                                                    area={propertyDetail.area}
                                                    offerVendorName={propertyDetail.offer.vendor.name}
                                                    offerVendorLogo={propertyDetail.offer.vendor.logo?.v_log_80x60}
                                                    offerPrivacyPolicyUrl={propertyDetail.offer.configuration.privacy_policy_url}
                                                    offerPrivacyDataProcessorName={propertyDetail.offer.configuration?.personal_data_processor_name}
                                                    offerPersonalDataProcessorUrl={propertyDetail.offer.configuration?.personal_data_processor_url}
                                                    selectedPropertyIds={selectedPropertyIds}
                                                    togglePropertySelection={togglePropertySelection}
                                                />
                                            ))}
                                        </>
                                    )}
                                </div>
                            </div>
                        </Expandable>
                    </AdvancedScroll>
                )}
                <div css={formColumnStyle}>
                    <YourData
                        hideTopLabel
                        showEmailField={props.showEmailField}
                        showPhoneField={props.showPhoneField}
                        showNameField={props.showNameField}
                        onShowAllInputFieldsClick={props.showAllInputFields}
                        css={yourDataStyle}
                    />
                    <OptIns
                        showPropertyNotificationField={props.showPropertyNotificationField}
                        showFinancingServicesField={props.showFinancingServicesField}
                        showPropertyRentalPriceField={props.showRentalPriceField}
                        copyType={!!property ? FinancingServicesCopyTypes.SINGLE_PROPERTY : FinancingServicesCopyTypes.NONE_PROPERTY}
                    />
                    <div>
                        {(!isAuthenticated || !formPreviouslySent) && <TermsAcceptation />}

                        {!hideSubmitButton && (
                            <SubmitButton css={mt(2)} type="submit" variant="filled_primary" disabled={leadIsSubmitting}>
                                {leadIsSubmitting ? <Loader /> : <>Wyślij</>}
                            </SubmitButton>
                        )}
                    </div>
                    <div>
                        <ApplicationFormRodoTerms offer={offer} vendor={vendor} />
                    </div>
                </div>
            </div>
        </div>
    );
};

const wrapperStyle = (theme: Theme) => css`
    ${p(1.5)};
    background-color: ${theme.colors.gray[100]};

    ${onDesktop(css`
        width: 90rem;
        ${p(3, 3, 0, 0)};
    `)};
`;

const headerStyle = css`
    ${mb(3)};
    display: flex;
    justify-content: space-between;

    ${onDesktop(css`
        ${pl(3)};
    `)};
`;

const closeIconWrapperStyle = css`
    width: 2.4rem;
    height: 2.4rem;
    ${flexAbsoluteCenter};
    cursor: pointer;
`;

const contentStyle = css`
    ${onDesktop(css`
        ${flex()};
        flex-direction: row;
        gap: ${calculateRemSize(3)};
    `)};
`;

const offersColumnStyle = css`
    ${onDesktop(css`
        margin-top: -${calculateRemSize(1)};
    `)};
`;

const offersColumnContentStyle = css`
    ${onDesktop(css`
        ${p(1, 2, 2, 3)};
    `)};
`;

const applicationOfferSentSummaryBoxMaskStyle = css`
    position: absolute;
    inset: 0 0 auto 0;
    height: 10px;
    z-index: 3;
`;

const contentTopShadow = css`
    background: linear-gradient(rgba(249, 249, 249, 1), rgba(249, 249, 249, 0));
`;

const contentBottomShadow = css`
    background: linear-gradient(rgba(249, 249, 249, 0), rgba(249, 249, 249, 1));
`;

const expandableButtonStyle = css`
    transform: translateY(-100%);
`;

const retargetedOffersWrapperStyle = css`
    overflow: hidden;

    ${onDesktop(css`
        background-color: #fff;
        ${borderRadius(2)};
    `)};
`;

const retargetedOfferListStyle = css`
    ${pb(2)};
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: ${calculateRemSize(1.5)};

    ${onDesktop(css`
        ${p(2, 1)};
    `)};
`;

const formColumnStyle = css`
    ${onDesktop(css`
        flex: 0 0 34rem;
    `)};
`;

export const yourDataStyle = css`
    ${m(0, 0, 1.5, 0)};

    ${onDesktop(css`
        ${m(0, 0, 3, 0)};
    `)};
`;
