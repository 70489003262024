import {IApplicationAdditionalQuestionTypes} from "../../../components/application_form/ApplicationFormContext";

export const hasPassedProperPropertyNumber = <TContext extends {propertyNumber?: string}>(context: TContext): context is TContext & {propertyNumber: string} =>
    !!context.propertyNumber;

export const hasSelectedAnyRooms = (selectedRooms: number[]): boolean => selectedRooms && selectedRooms.length > 0;

export const OPTION_WITH_THE_MOST_ROOMS = 5;
export const hasSelectedLargestRoomOption = (selectedRooms: number[]): boolean => selectedRooms && selectedRooms.includes(OPTION_WITH_THE_MOST_ROOMS);

export const hasSimilarAptsQuestion = (selectedQuestions: IApplicationAdditionalQuestionTypes[]) => selectedQuestions.indexOf("similarApts") > -1;
