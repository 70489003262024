/* eslint-disable @typescript-eslint/no-unused-vars */
import {IABTestConfiguration} from "@pg-mono/ab-testing";
import {rpAppPath} from "@pg-mono/rp-routes";

export enum OngoingABTests {
    VENDOR_MULTILEAD = "VENDOR_MULTILEAD"
}

/**
 * Example of a test configuration:
 * {
 *     id: "xyz123" // Generated id, received from business,
 *     expires: new Date(2024, 3, 30), // Date when the test will expire,
 *     name: OngoingABTests.SOME_FEATURE, // Name of the test,
 *     path: ["some-path"], // Paths where the test will be active,
 *     matchOnAllPaths: false, // If true, the test will be active on all paths,
 *     variants: 3, // Number of variants, the actual amount is variants + 1 (first variant is 0)
 *     withSSR: false // If true, the test will be active on server side rendering
 * }
 */

export const ongoingABTests: IABTestConfiguration[] = [
    {
        id: "3Kzks699cw59Z3b0bo7e",
        expires: new Date(2025, 5, 1),
        name: OngoingABTests.VENDOR_MULTILEAD,
        path: [],
        matchOnAllPaths: true,
        variants: 1,
        withSSR: false
    }
];
