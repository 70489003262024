import React from "react";
import {css, Theme} from "@emotion/react";

import {IFormFieldProps} from "@pg-design/formik-utils";
import {borderRadius} from "@pg-design/helpers-css";

import {SelectGroupColorVariants, SelectGroupItem} from "./SelectGroupItem";

export type SelectGroupOptionValueTypes = string | number;

export type ISelectGroupOption<ValueType extends SelectGroupOptionValueTypes> = {
    value: ValueType;
    label: string;
    isActive?: boolean;
    isNonActive?: boolean;
    isDisabled?: boolean;
};

export type SelectGroupFormFieldProps<ValueType> = Omit<IFormFieldProps<string, ValueType>, "checked" | "onAfterChange">;

export interface ISelectGroupFieldProps<ValueType extends SelectGroupOptionValueTypes> extends SelectGroupFormFieldProps<ValueType> {
    className?: string;
    options: ISelectGroupOption<ValueType>[];
    id?: string;
    required?: boolean;
    variant?: SelectGroupColorVariants;
}

export const SelectGroupField = <ValueType extends SelectGroupOptionValueTypes>(props: ISelectGroupFieldProps<ValueType>) => {
    /**
     * Render
     */
    return (
        <div css={checkboxList} className={props.className}>
            {props.options.map((option) => (
                <SelectGroupItem
                    key={option.value}
                    name={props.name}
                    value={option.value}
                    onChange={props.onChange}
                    label={option.label}
                    isActive={option.isActive}
                    isNonActive={option.isNonActive}
                    isDisabled={option.isDisabled}
                    variant={props.variant}
                />
            ))}
        </div>
    );
};

const checkboxList = (theme: Theme) => css`
    padding: 0;
    list-style: none;
    display: flex;
    font-size: 1.2rem;
    width: 100%;
    align-items: stretch;
    border: 1px solid ${theme.colors.gray[600]};
    ${borderRadius(1)}
    overflow: hidden;
    word-wrap: normal;
    height: 4.8rem;
    min-height: 4.8rem;
`;
