import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";

import {Button} from "@pg-design/button";
import {flexAbsoluteCenter, h100, mb, mr, p} from "@pg-design/helpers-css";
import {PhoneIcon} from "@pg-design/icons";
import {IRange} from "@pg-design/inputs";
import {Loader} from "@pg-design/loader";
import {Text} from "@pg-design/text";
import {phoneNumberFormat} from "@pg-mono/string-utils";

import {IRPStore} from "../../app/rp_reducer";
import {goodbyeModalContentInitialized} from "../../tracking/algolytics_hits/goodbye_modal_content_initialized_hit";
import {gtmRotatingPhoneNumbersCall, gtmRotatingPhoneNumbersReveal} from "../../tracking/google_tag_manager/gtm_rotating_phone_numbers";
import {useClipPhoneNumber} from "../hooks/use_clip_phone_number";
import {ApplicationSource, ApplicationSourceSection} from "../utils/ApplicationSource";
import {VendorDisplay} from "./application_form/components/VendorDisplay";
import {RodoFormInfo} from "./RodoFormInfo";

export interface IOfferTrader {
    image: string;
    name: string;
    id: number;
}

export interface IOfferSalesOffice {
    address: string;
    /*image: {
        small: string;
        large: string;
    };*/
    id: number;
    mobile: string;
    name: string;
    phone: string;
    open_hours_1: IRange<string>;
    open_hours_2: IRange<string>;
    open_hours_3: IRange<string>;
    open_hours_4: IRange<string>;
    open_hours_5: IRange<string>;
    open_hours_6: IRange<string>;
    open_hours_7: IRange<string>;
    info_note_1: string;
    info_note_2: string;
    info_note_3: string;
}

export interface IApplicationGoodbyeContentProps {
    // data common from `IDesktopApplicationOwnProps` and `IDesktopScheduleMeetingOwnProps`
    storeHash: string;
    source: ApplicationSource;
    sourceSection: ApplicationSourceSection;
    // additional props
    className?: string;
    traders?: IOfferTrader[];
    salesOffice?: IOfferSalesOffice;
    closeModal: () => void;
}

export const ApplicationGoodbyeContent = (props: IApplicationGoodbyeContentProps) => {
    const [showGoodbyeContent, setShowGoodbyeContent] = useState(true);
    const [showPhoneNumberContent, setShowPhoneNumberContent] = useState(false);
    const data = useSelector((state: IRPStore) => state.application[props.storeHash]);

    useEffect(() => {
        checkForExceptionsInClosingModal();
    }, []);

    const checkForExceptionsInClosingModal = () => {
        const vendor = data && data.vendor;
        const show_form_placeholder = vendor && vendor.detail && vendor.detail.configuration && vendor.detail.configuration.show_form_placeholder;
        const phoneNumber = getPhoneNumber();

        if (show_form_placeholder || !phoneNumber) {
            setShowGoodbyeContent(false);
            return props.closeModal();
        }

        goodbyeModalContentInitialized(props.source, props.sourceSection, "third_scenario");
    };

    const getPhoneNumber = (): string | undefined => {
        const offer = data && data.offer;
        const vendor = data && data.vendor;
        const phone_clip = offer && offer.detail && offer.detail.configuration && offer.detail.configuration.phone_clip;
        const is_phone_clip_enabled = offer && offer.detail && offer.detail.is_phone_clip_enabled;
        const salesOffice = vendor && vendor.detail && vendor.detail.offices;

        if (phone_clip && is_phone_clip_enabled) {
            return phoneNumberFormat(phone_clip);
        }

        if (salesOffice && salesOffice.length) {
            const salesOfficeNumber = salesOffice[0].phone || salesOffice[0].mobile;
            return phoneNumberFormat(salesOfficeNumber as string);
        }
    };

    const {phoneNumber, fetchClipPhoneNumber, isFetching} = useClipPhoneNumber(getPhoneNumber());

    const onShowPhoneNumberButtonClick = async () => {
        setShowPhoneNumberContent(true);
        gtmRotatingPhoneNumbersReveal("modal");
        await fetchClipPhoneNumber(data?.property?.detail?.id);
    };

    if (!showGoodbyeContent) {
        return null;
    }

    const vendorDetail = data?.vendor?.detail || null;
    const offerDetail = data?.offer?.detail || null;

    return (
        <div css={goodbyeHolder}>
            <VendorDisplay offer={offerDetail} onCloseModal={props.closeModal} vendor={vendorDetail} css={vendorDisplayStyle} />
            <Text css={heading} align="center">
                Może chcesz skontaktować się z deweloperem telefonicznie?
            </Text>

            {!showPhoneNumberContent ? (
                <div css={[mb(3), flexAbsoluteCenter]}>
                    <Button onClick={onShowPhoneNumberButtonClick} type="button" variant="outlined_secondary" css={flexAbsoluteCenter}>
                        <PhoneIcon size="2.4" wrapperSize="4" css={mr(2)} />
                        POKAŻ NUMER
                    </Button>
                </div>
            ) : isFetching ? (
                <Loader size="sm" />
            ) : phoneNumber ? (
                <CallBtn variant="filled_primary" iconLeft={PhoneIcon} href={`tel:+${phoneNumber}`} onClick={() => gtmRotatingPhoneNumbersCall("modal")}>
                    {phoneNumber || "BRAK DANYCH KONTAKTOWYCH"}
                </CallBtn>
            ) : null}

            <RodoFormInfo
                vendor={vendorDetail}
                offerPrivacyPolicyUrl={offerDetail?.configuration.privacy_policy_url}
                offerPersonalDataProcessorName={offerDetail?.configuration.personal_data_processor_name}
                offerPersonalDataProcessorUrl={offerDetail?.configuration.personal_data_processor_url}
                css={rodoStyle}
            />
        </div>
    );
};

const goodbyeHolder = (theme: Theme) => css`
    ${p(6, 2, 6, 2)};
    ${h100};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @media (min-width: ${theme.breakpoints.md}) {
        ${p(5)};
    }
`;

const vendorDisplayStyle = (theme: Theme) => css`
    margin-bottom: 128px;
    display: flex;
    width: 100%;
    justify-content: center;

    @media (min-width: ${theme.breakpoints.md}) {
        margin-bottom: 96px;
        text-align: center;
    }
`;

const heading = (theme: Theme) => css`
    ${mb(4)};
    color: ${theme.colors.gray[800]};
    max-width: 504px;

    @media (min-width: ${theme.breakpoints.md}) {
        ${mb(4)};
    }
`;

const CallBtn = styled(Button)`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;

    @media (min-width: ${(props) => props.theme.breakpoints.md}) {
        width: 50rem;
    }
`;

const rodoStyle = (theme: Theme) => css`
    margin-top: auto;

    @media (min-width: ${theme.breakpoints.md}) {
        width: 60%;
    }
`;
