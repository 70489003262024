import {createContext, PropsWithChildren, useContext, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import type {Dispatch} from "redux";

import {useIsMobile} from "@pg-mono/hooks";

import {IRPStore} from "../../../app/rp_reducer";
import {ISentLeadActions, setSentLeadValuesAction} from "../../../lead/reducers/sent_lead_reducer";
import {IApplicationAnalyticsData} from "../../tracking/tracking";
import {IApplicationOffer} from "../../types/IApplicationOffer";
import {IApplicationProperty} from "../../types/IApplicationProperty";
import {IApplicationVendor} from "../../types/IApplicationVendor";
import {MessageType} from "../../types/MessageType";
import {ApplicationSource, ApplicationSourceSection} from "../../utils/ApplicationSource";

interface IContextValue {
    // tracking
    analyticsData: IApplicationAnalyticsData;
    source: ApplicationSource;

    // form field visibility
    alwaysShowFields?: boolean;
    enableLegalDetailsHiding?: boolean;
    hideFinancingServices?: boolean;
    hideMessageAndQuestionsSection?: boolean;
    hidePropertyNotification?: boolean;
    hideQuestions?: boolean;
    hideRooms?: boolean;
    // TODO: Remove this field from this context
    hideRodo?: boolean;
    hideSubmitButton?: boolean;

    // data
    property: IApplicationProperty | null;
    offer: IApplicationOffer | null;
    offerId?: number;
    vendor: IApplicationVendor | null;

    // rest
    applicationMessageLogic: IApplicationMessageLogic;
    isAuthenticated?: boolean;
    isMobile: boolean;
    formPreviouslySent: boolean;
    onFormControlClick?: () => void;
    storeHash: string;
    onCloseModal: (forceClose?: boolean) => void;
    formDataInitialized?: boolean;
    isRequiredUserDataFilled?: boolean;
    emailNotificationProps: {
        isActive: boolean;
        onClose: () => void;
    };

    //  post
    leadIsSubmitting: boolean;
    customFormText?: string;
}

type IApplicationFormProviderProps = PropsWithChildren<
    Omit<IContextValue, "property" | "offer" | "vendor" | "viewType" | "analyticsData" | "isMobile"> & {
        sourceSection: ApplicationSourceSection;
        getAnalyticsData: () => IApplicationAnalyticsData;
    }
>;

export interface IApplicationMessageLogic {
    currentState: {
        messageType: MessageType;
        selectedQuestions: IApplicationAdditionalQuestionTypes[];
        generatedMessage: string | null;
    };
    helpers: {
        setMessageType: (messageType: MessageType) => void;
        setSelectedQuestions: (questionsIds: IApplicationAdditionalQuestionTypes[]) => void;
        setGeneratedMessage: (generatedMessage: string) => void;
    };
}

export type IApplicationAdditionalFlatQuestionTypes = "price" | "parkAndStorage" | "rent" | "payPlan" | "areaInfr" | "similarApts";

export type IApplicationAdditionalHouseQuestionTypes = "price" | "payPlan" | "areaInfr" | "heatingType" | "gardenSize" | "similarApts";

export type IApplicationAdditionalQuestionTypes = IApplicationAdditionalFlatQuestionTypes | IApplicationAdditionalHouseQuestionTypes;

const ApplicationFormContext = createContext<IContextValue | null>(null);

export const ApplicationFormContextProvider = (props: IApplicationFormProviderProps) => {
    const {children, getAnalyticsData, ...contextValues} = props;

    const isMobile = useIsMobile();
    const dispatch: Dispatch<ISentLeadActions> = useDispatch();

    const applicationData = useSelector((state: IRPStore) => state.application[contextValues.storeHash]);
    const storedQuestions = useSelector((state: IRPStore) => state.sentLead.storedValues?.questions);

    const selectedQuestions = contextValues.applicationMessageLogic.currentState.selectedQuestions;

    useEffect(() => {
        dispatch<ISentLeadActions>(setSentLeadValuesAction({questions: selectedQuestions}));
    }, [selectedQuestions]);

    useEffect(() => {
        // initialize additional questions
        const isPropertyWithPrice = applicationData?.property?.detail?.price || applicationData?.property?.detail?.price_m2;
        let selectedQuestions: IApplicationAdditionalQuestionTypes[] = storedQuestions || [];
        if (isPropertyWithPrice) {
            selectedQuestions = selectedQuestions.filter((question) => question !== "price");
        }
        if (!isPropertyWithPrice && !selectedQuestions.includes("price")) {
            selectedQuestions.unshift("price");
        }

        contextValues.applicationMessageLogic.helpers.setSelectedQuestions(selectedQuestions);
    }, [storedQuestions]);

    const property = applicationData?.property?.detail || null;
    const offer = applicationData?.offer?.detail || null;
    const vendor = applicationData?.vendor?.detail || null;
    const offerId = contextValues.offerId;

    return (
        <>
            <ApplicationFormContext.Provider
                value={{
                    ...contextValues,
                    property: property,
                    offer: offer,
                    offerId,
                    vendor: vendor,
                    isMobile,
                    analyticsData: getAnalyticsData(),
                    customFormText: props.customFormText
                }}
            >
                {children}
            </ApplicationFormContext.Provider>
        </>
    );
};

export const useApplicationFormContext = () => {
    const context = useContext(ApplicationFormContext);

    if (!context) {
        throw new Error("No ApplicationFormContext found when calling useApplicationFormContext.");
    }

    return context;
};
