import React from "react";
import {css, Theme} from "@emotion/react";

import {mb} from "@pg-design/helpers-css";

interface IProps extends React.LabelHTMLAttributes<HTMLLabelElement> {
    children: React.ReactNode;
}

export const FieldLabel = (props: IProps) => {
    const {children, ...labelProps} = props;

    return (
        <label css={labelStyle} {...labelProps}>
            {children}
        </label>
    );
};

const labelStyle = (theme: Theme) => css`
    ${mb(2)};
    display: block;
    font-size: 1.4rem;
    font-weight: 700;
    color: ${theme.colors.secondary};
`;
