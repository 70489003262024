import {getRequest} from "@pg-mono/request";
import {apiV2Link, Scenario} from "@pg-mono/rp-api-routes";

import {clientRpRequestMeta} from "../../../client/client_rp_request_meta";
import {IRecommendedOffer} from "../../recommendations/types/IRecommendedOffer";
import {gtmInquiryOnApplicationSuccess} from "../../tracking/google_tag_manager/gtm_inquiry";
import {ViewType} from "../../view_type/ViewType";
import {IApplicationData} from "../reducers/application_reducer";
import {IApplication} from "../types/IApplication";
import {IApplicationOffer} from "../types/IApplicationOffer";

export type RecommendedOfferApplicationsType = [IApplication, IRecommendedOffer];

// Application lacks information like region, therefore we need to do another fetch in order to suplement the data required by GTM
const fetchRecommendedOffer = (offerId: number): Promise<IApplicationOffer> => {
    return getRequest(clientRpRequestMeta, apiV2Link.offer.detail(Scenario.OFFER_APPLICATION, {offerId}));
};

export const trackRecommendedOffersApplication = (applications: RecommendedOfferApplicationsType[], viewType: ViewType | null): void => {
    // We need to fetch property offer data in order to make GTM event after application submit success
    Promise.all(applications.map(([application]) => fetchRecommendedOffer(application.offer))).then((offers) => {
        offers.forEach((offer, index) => {
            // Order of response from Promise.all method is preserved so we can get value from `applications` basing on the index
            const [application, recommendedOffer] = applications[index];

            //GTM purchase event
            const data = {
                offer: {detail: offer},
                vendor: {detail: recommendedOffer.vendor}
            } as unknown as IApplicationData;

            gtmInquiryOnApplicationSuccess(application, data, viewType, true);
        });
    });
};
