import {Dispatch} from "redux";

import {catch400, catch403, patchRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiUserLink} from "@pg-mono/rp-api-routes";

import {IUserProfile} from "../ts/interfaces/IUserProfile";
import {getUserApiRequestMeta} from "../utils/get_user_api_request_meta";

export type IUserProfilePatch = Partial<Pick<IUserProfile, "name" | "phone">>;

const PATCH_USER_PROFILE_PREFIX = "user/PATCH_USER_PROFILE";
export const patchUserProfileRequestType = createRequestActionTypes(PATCH_USER_PROFILE_PREFIX);

export const patchUserProfile = (data: IUserProfilePatch) => (dispatch: Dispatch) => {
    const userApiMeta = getUserApiRequestMeta({});
    dispatch({type: patchUserProfileRequestType.start});

    return patchRequest(userApiMeta, apiUserLink.user.info, data)
        .then((userProfile: IUserProfile) => {
            dispatch({type: patchUserProfileRequestType.success, result: userProfile});

            return userProfile;
        })
        .catch(
            catch403(() => {
                dispatch({type: patchUserProfileRequestType.reset});
            })
        )
        .catch(
            catch400((error) => {
                dispatch({type: patchUserProfileRequestType.error, error: error.appError});
            })
        );
};
