import React, {useState} from "react";
import {useSelector} from "react-redux";
import {css, Global, Theme} from "@emotion/react";
import {useFormikContext} from "formik";

import {FormikForm} from "@pg-design/formik-utils";
import {mb} from "@pg-design/helpers-css";
import {Popover} from "@pg-design/notifications";

import {IRPStore} from "../../../../app/rp_reducer";
import {IApplicationFormValues} from "../../../types/IApplicationFormValues";
import {EmailVerificationPopover} from "../../EmailVerificationPopover";
import {useApplicationFormContext} from "../ApplicationFormContext";
import {FieldLabel} from "../components/FieldLabel";

interface IProps {
    className?: string;
    showNameField: boolean;
    showEmailField: boolean;
    showPhoneField: boolean;
    onShowAllInputFieldsClick: () => void;
    hideTopLabel?: boolean;
}
export const YourData = (props: IProps) => {
    const {className, showNameField, showEmailField, showPhoneField, onShowAllInputFieldsClick, hideTopLabel} = props;
    const {formPreviouslySent, isMobile, onFormControlClick, emailNotificationProps} = useApplicationFormContext();
    const countryCodes = useSelector((state: IRPStore) => state.variables.data?.country_codes ?? []);

    const [wasNameFieldFocused, setWasNameFieldFocused] = useState(false);

    const displayShowAllLink = !showNameField || !showEmailField || !showPhoneField;

    const {errors} = useFormikContext<IApplicationFormValues>();

    return (
        <div css={yourDataStyle} className={className}>
            {!hideTopLabel && <FieldLabel>Twoje dane</FieldLabel>}
            <Global styles={resetMarginStyle} />
            {(showNameField || errors.name) && (
                <Popover
                    isActive={wasNameFieldFocused && !formPreviouslySent}
                    body="Specjalnie dla Ciebie stworzymy w serwisie Twoje własne konto, gdzie będziesz miał historię swoich zapytań i ulubionych ofert. W ramach naszej usługi będziesz dostawał mailem lub smsem linki do nieruchomości takich lub podobnych do wybranej przez Ciebie."
                    toggleFocusHandler
                    toggleBlurHandler
                    popoverPlace={isMobile ? "top" : "left"}
                    arrow={!isMobile}
                    // INFO: This type is confusing in this place, but we want to use dark background popover
                    // Maybe we should rename this type to `neutral` or sth similar.
                    type="failure"
                >
                    <FormikForm.Input
                        name="name"
                        maxLength={100}
                        placeholder="Imię i nazwisko"
                        onClick={onFormControlClick}
                        onFocus={() => setWasNameFieldFocused(true)}
                        onBlur={() => setWasNameFieldFocused(false)}
                        css={mb(2)}
                    />
                </Popover>
            )}
            {(showEmailField || errors.email) && (
                <EmailVerificationPopover {...emailNotificationProps} zIndex={10000}>
                    <FormikForm.Input name="email" maxLength={254} type="email" placeholder="Email" onClick={onFormControlClick} css={mb(2)} />
                </EmailVerificationPopover>
            )}

            {(showPhoneField || errors.phone) && (
                <FormikForm.PhoneField name="phone" codes={countryCodes} onClick={onFormControlClick} placeholder="Numer telefonu" className="bt" />
            )}

            {displayShowAllLink && (
                <div onClick={onFormControlClick}>
                    <div onClick={onShowAllInputFieldsClick}>zmień dane kontaktowe</div>
                </div>
            )}
        </div>
    );
};

const yourDataStyle = css`
    display: flex;
    flex-direction: column;
`;

const resetMarginStyle = (theme: Theme) => css`
    body .reset-margin {
        margin: 0;
    }
    body .new-field-margin {
        margin: 0 0 1.9rem 0;
        @media (min-width: ${theme.breakpoints.md}) {
            margin: 0 0 1.2rem 0;
        }
    }
`;
