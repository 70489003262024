import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const ChevronUpIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M5.026 4.028 2 7V4.973L5.026 2l3.027 2.973V7L5.026 4.028Z" />
        </SvgIcon>
    );
};
