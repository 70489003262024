import React, {useState} from "react";
import {css, SerializedStyles, useTheme} from "@emotion/react";

import {borderRadius, flex, p} from "@pg-design/helpers-css";
import {Image} from "@pg-design/image";
import {Text} from "@pg-design/text";

interface IProps {
    className?: string;
    content?: React.ReactNode;
    coverCss?: SerializedStyles;
    rightSlot?: React.ReactNode;
    title: string;
    address: string;
    imgSrc?: string;
}

export const ApplicationOfferBoxBase = (props: IProps) => {
    const theme = useTheme();

    return (
        <div css={wrapperStyle} className={props.className}>
            <div css={[offerCoverStyle, props.coverCss]}>
                <Image src={props.imgSrc} width="100%" height="130px" alt="" css={imgCoverStyle} />
            </div>
            <div css={contentStyle}>
                <Text variant="body_copy_2" mb="0.4rem">
                    {props.title}
                </Text>
                <Text variant="info_txt_3" mb="0.4rem" color={theme.colors.gray[700]}>
                    {props.address}
                </Text>
                {props.content}
            </div>
            {props.rightSlot ? <div>{props.rightSlot}</div> : null}
        </div>
    );
};

ApplicationOfferBoxBase.Logo = (props: {imgSrc?: string; alt: string; className?: string}) => {
    const [hasError, setHasError] = useState(false);
    return (
        <div css={!hasError && logoWrapper} className={props.className}>
            {!hasError && <Image src={props.imgSrc} width="64px" height="48px" alt={props.alt} onError={() => setHasError(true)} />}
        </div>
    );
};

const wrapperStyle = css`
    ${flex()};
    flex-direction: row;
    position: relative;
    ${borderRadius(1)};
    background-color: #fff;
`;

const offerCoverStyle = css`
    ${borderRadius(1, 0, 0, 1)};
    flex: 0 0 96px;
    overflow: hidden;
`;

const imgCoverStyle = css`
    display: block;
    height: 100%;

    img {
        height: 100%;
        object-fit: cover;
    }
`;

const contentStyle = css`
    ${p(1)};
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;
`;

const logoWrapper = css`
    ${flex("center", "flex-start")};
    width: 6.4rem;
    height: 4.8rem;
`;
