import {IApplicationData} from "../../application/reducers/application_reducer";

export function isLeadWithPriceConfirmation(leadData: IApplicationData | null) {
    /**
     * According to business requirements (CU-862j5xdx7) if application is related to property and property has defined price,
     * and price should be visible - then we ask the user to confirm sending the application.
     */

    return Boolean(leadData && !!leadData.property?.detail?.price && leadData.property.detail.configuration.show_price);
}
