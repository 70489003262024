import React from "react";
import {css, Theme} from "@emotion/react";

import {mb} from "@pg-design/helpers-css";
import {SelectGroupField} from "@pg-design/select-group";

import {MessageType} from "../../../types/MessageType";
import {useApplicationFormContext} from "../ApplicationFormContext";

interface IProps {
    className?: string;
}

const fieldName = "message_type";

export const MessageTypePicker = (props: IProps) => {
    const {className} = props;

    const {onFormControlClick, applicationMessageLogic} = useApplicationFormContext();
    const {currentState, helpers} = applicationMessageLogic;

    const value = currentState.messageType;

    const checkboxListItems = [
        {
            value: MessageType.GENERATED,
            label: "Chcę zapytać o:",
            isActive: value === MessageType.GENERATED
        },
        {
            value: MessageType.CUSTOM,
            label: "Własna wiadomość",
            isActive: value === MessageType.CUSTOM
        }
    ];

    const onCheckboxSelect = (fieldName: string, value: MessageType) => {
        helpers.setMessageType(parseInt(value.toString()));
    };

    return (
        <div onClick={onFormControlClick} css={contactTypeStyle} className={className}>
            <SelectGroupField options={checkboxListItems} name={fieldName} onChange={onCheckboxSelect} value={value} variant="gray" />
        </div>
    );
};

const contactTypeStyle = (theme: Theme) => css`
    display: flex;
    flex-direction: column;

    @media (max-height: 740px) and (min-width: ${theme.breakpoints.md}) {
        ${mb(1)};
    }
`;
