import {isEmpty} from "@pg-mono/nodash";

import {IApplication} from "../../application/types/IApplication";
import {IApplicationFormValues} from "../../application/types/IApplicationFormValues";
import {ApplicationSourceSection} from "../../application/utils/ApplicationSource";
import {hitApplicationSuccess, IApplicationExtraPayload} from "../../tracking/algolytics_hits/application_hit";
import {ViewType} from "../../view_type/ViewType";
import {IHitApplicationData} from "../types/IHitApplicationData";

interface IHitLeadSuccessParams {
    locationHash: string;
    viewType: ViewType | null;
    rooms: IApplicationFormValues["rooms"];
    leadResponse: IApplication;
    sourceSection: ApplicationSourceSection;
    leadData: IHitApplicationData;
}

export function hitLeadSuccess(params: IHitLeadSuccessParams) {
    const {locationHash, viewType, rooms, leadResponse, sourceSection, leadData} = params;

    const extraPayload: IApplicationExtraPayload = {
        extraPayloadViewType: viewType ?? undefined
    };

    if (locationHash.includes("recommendation")) {
        extraPayload.recommendation = true;
    } else if (locationHash.includes("push")) {
        extraPayload.recommendation = true;
        extraPayload.push = true;
    }

    if (locationHash.includes("my_offer_list")) {
        extraPayload.my_offer_list = true;
    }

    if (!isEmpty(rooms)) {
        extraPayload.rooms = rooms;
    }

    hitApplicationSuccess(leadResponse, sourceSection, leadData, extraPayload);
}
