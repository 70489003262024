// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";
import styled from "@emotion/styled";

import {flexAbsoluteCenter, p, zIndex} from "@pg-design/helpers-css";

export const AbsoluteContent = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    ${zIndex(10001)}; // must be bigger than any form component
    width: 100%;
    height: 100%;
    background-color: white;
    ${p(4)};
    flex-direction: column;
    ${flexAbsoluteCenter};
`;
