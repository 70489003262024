import {hitGoogleTagManager} from "./init_google_tag_manager";

interface IGtmApplicationPutAside {
    event: "application_put_aside";
    property_brand: string;
    property_category: string;
    offerId: string;
    vendorId: string;
}

export const gtmApplicationPutAside = (vendor?: {id: number; name: string}, offer?: {id: number; name: string}): boolean => {
    // prepare gtm data and hit
    const gtmData: IGtmApplicationPutAside = {
        event: "application_put_aside",
        property_brand: vendor ? `${vendor.id}/${vendor.name}` : "na",
        property_category: offer ? `${offer.id}/${offer.name}` : "na",
        offerId: offer ? offer.id.toString() : "na",
        vendorId: vendor ? vendor.id.toString() : "na"
    };
    hitGoogleTagManager(gtmData);
    return true;
};
