import React from "react";
import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";

import {flex, mb, mt, p, pb} from "@pg-design/helpers-css";
import {Loader} from "@pg-design/loader";
import {Text} from "@pg-design/text";

import {FinancingServicesCopyTypes} from "../../../../lead/types/FinancingServicesCopyTypes";
import {MessageType} from "../../../types/MessageType";
import {ApplicationSource} from "../../../utils/ApplicationSource";
import {TermsAcceptation} from "../../TermsAcceptation";
import {useApplicationFormContext} from "../ApplicationFormContext";
import {OptIns} from "../fields/OptIns";
import {SelectRooms} from "../fields/SelectRooms";
import {YourData} from "../fields/YourData";
import {ApplicationFormRodoTerms} from "./ApplicationFormRodoTerms";
import {MessageAndQuestionsSection} from "./MessageAndQuestionsSection";
import {PropertyInfo} from "./PropertyInfo";
import {SubmitButton} from "./SubmitButton";
import {VendorDisplay} from "./VendorDisplay";

interface IProps {
    showEmailField: boolean;
    showPhoneField: boolean;
    showNameField: boolean;
    showAllInputFields: () => void;
    messageType: MessageType;
    showTextAreaOnly: boolean;
    showPropertyNotificationField: boolean;
    showFinancingServicesField: boolean;
    showRentalPriceField: boolean;
}

export const ApplicationDefaultForm = (props: IProps) => {
    const {offer, property, vendor, hideRooms, hideSubmitButton, source, isAuthenticated, formPreviouslySent, onCloseModal, leadIsSubmitting} =
        useApplicationFormContext();

    return (
        <div css={formWrapperStyle}>
            <div css={leftSideStyle}>
                <div css={vendorHeaderStyle}>
                    <VendorDisplay offer={offer} onCloseModal={onCloseModal} vendor={vendor} css={vendorDisplayStyle} />
                </div>
                <PropertyInfo css={mb(3)} />
                <div css={leftFieldsWrapStyle}>
                    {!hideRooms && <SelectRooms label="Liczba pokoi, która mnie interesuje" css={mb(2)} />}
                    <YourData
                        showEmailField={props.showEmailField}
                        showPhoneField={props.showPhoneField}
                        showNameField={props.showNameField}
                        onShowAllInputFieldsClick={props.showAllInputFields}
                        css={yourDataStyle}
                    />

                    <div css={leftRodoTermsStyle}>
                        <ApplicationFormRodoTerms offer={offer} vendor={vendor} />
                    </div>
                </div>
            </div>
            <RightSideWrapper>
                {props.messageType !== MessageType.NONE && (
                    <MessageAndQuestionsSection showMessageOnly={props.showTextAreaOnly} messageType={props.messageType} />
                )}

                <OptIns
                    showPropertyNotificationField={props.showPropertyNotificationField}
                    showFinancingServicesField={props.showFinancingServicesField}
                    showPropertyRentalPriceField={props.showRentalPriceField}
                    copyType={!!property ? FinancingServicesCopyTypes.SINGLE_PROPERTY : FinancingServicesCopyTypes.NONE_PROPERTY}
                />
                <div>
                    {(!isAuthenticated || !formPreviouslySent) && <TermsAcceptation />}

                    {!hideSubmitButton && (
                        <SubmitButton css={mt(2)} type="submit" variant="filled_primary" disabled={leadIsSubmitting}>
                            {leadIsSubmitting ? <Loader /> : <>Wyślij</>}
                        </SubmitButton>
                    )}

                    {source === ApplicationSource.RevealedPrices && (
                        <Text variant="body_copy_1">
                            Wypełnij formularz, żeby poznać cenę tego mieszkania. Deweloper może skontaktować się z Tobą, żeby przedstawić Ci szczegóły oferty.
                        </Text>
                    )}
                </div>

                <div css={rightRodoTermsStyle}>
                    <ApplicationFormRodoTerms offer={offer} vendor={vendor} />
                </div>
            </RightSideWrapper>
        </div>
    );
};

//  Styles
const formWrapperStyle = (theme: Theme) => css`
    display: flex;
    flex-direction: column;

    @media (min-width: ${theme.breakpoints.md}) {
        flex-direction: row;
    }
`;

const leftSideStyle = (theme: Theme) => css`
    display: flex;
    flex-direction: column;
    ${p(2, 2, 0, 2)};

    @media (min-width: ${theme.breakpoints.md}) {
        ${p(2)};
        width: 35.4rem;
    }
`;

const leftFieldsWrapStyle = (theme: Theme) => css`
    @media (min-width: ${theme.breakpoints.md}) {
        height: 100%;
        ${flex("normal", "space-between")};
        flex-direction: column;
    }
`;

const vendorHeaderStyle = css`
    ${pb(3)};
    display: flex;
`;

const vendorDisplayStyle = css`
    flex: 1 1 auto;
`;

export const yourDataStyle = (theme: Theme) => css`
    margin: 0 0 1.2rem 0;

    @media (min-width: ${theme.breakpoints.md}) {
        margin: 0 0 2.6rem 0;
    }
`;

const leftRodoTermsStyle = (theme: Theme) => css`
    display: none;

    @media (min-width: ${theme.breakpoints.md}) {
        display: block;
    }
`;

const rightRodoTermsStyle = (theme: Theme) => css`
    display: block;

    @media (min-width: ${theme.breakpoints.md}) {
        display: none;
    }
`;

const RightSideWrapper = styled.div`
    ${p(2)};
    ${({theme}) => css`
        @media (min-width: ${theme.breakpoints.md}) {
            background-color: ${theme.colors.gray[100]};
            width: 45.6rem;
        }
    `}
`;
