import * as lscache from "lscache";

import {APPLICATION_LOCAL_STORAGE_KEY} from "../../app/constants/storage_keys";
import {IApplicationData} from "../../application/reducers/application_reducer";
import {getViewTypeName, ViewType} from "../../view_type/ViewType";
import {IGtmProduct} from "./common_interfaces/IGtmProduct";
import {calculateGtmDimensions, IGtmOffer, IGtmProperty, IGtmRegion, IGtmVendor} from "./calculate_gtm_dimensions";
import {hitGoogleTagManager} from "./init_google_tag_manager";

interface IGtmCheckout {
    event: "checkout";
    ecommerce: {
        checkout: {
            actionField: {step: 1};
            products: [IGtmProduct];
        };
    };
}

const gtmCheckout = (viewType: ViewType | null, vendor: IGtmVendor, region?: IGtmRegion, offer?: IGtmOffer, property?: IGtmProperty) => {
    // calculate financing services
    const savedInfo = lscache.get(APPLICATION_LOCAL_STORAGE_KEY);
    const financingServices = savedInfo && typeof savedInfo.financing_services === "boolean" ? savedInfo.financing_services : "na";
    // calculate product
    const product: IGtmProduct = {
        ...calculateGtmDimensions(financingServices, region, offer, property),
        name: (property && property.number) || "na",
        id: property ? property.id.toString() : "na",
        home_listing_id: offer ? (property ? `${offer.id}-${property.id}` : `${offer.id}-0`) : "na",
        group_id: offer ? `${offer.id}-0` : "na",
        price: "1.00",
        brand: vendor ? `${vendor.id}/${vendor.name}` : "na",
        category: offer ? `${offer.id}/${offer.name}` : "na",
        variant: viewType != null ? getViewTypeName(viewType) : "na",
        quantity: 1
    };
    // prepare gtm data and hit
    const gtmData: IGtmCheckout = {
        event: "checkout",
        ecommerce: {
            checkout: {
                actionField: {step: 1},
                products: [product]
            }
        }
    };
    hitGoogleTagManager(gtmData);
    return true;
};

/**
 * Specific actions
 */
export const gtmCheckoutOnApplicationInteraction = (data: IApplicationData, viewType: ViewType | null) => {
    const vendorData = (data.vendor && data.vendor.detail) || undefined;
    const offerData = (data.offer && data.offer.detail) || undefined;
    const finalOfferData = offerData ? {...offerData, region: offerData.region.id} : offerData;
    const regionData = (offerData && offerData.region) || undefined;
    const propertyData = (data.property && data.property.detail) || undefined;

    if (vendorData == null) {
        return console.error("interactionHitBasedOnData: require vendor data");
    }

    return gtmCheckout(viewType, vendorData, regionData, finalOfferData, propertyData);
};
