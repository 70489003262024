import type {Dispatch, MutableRefObject, SetStateAction} from "react";
import {useEffect, useRef, useState} from "react";
/*
 * Hook returns useState variables along with a constantly updated ref
 *
 * While using event listeners it is common to fall into issues caused by stale state being closed in callbacks.
 * We can store the state in a ref and use that in the listener callback to have current state always available.
 */
export const useStateRef = <T>(initialValue: T): [T, Dispatch<SetStateAction<T>>, MutableRefObject<T>] => {
    const [value, setValue] = useState<T>(initialValue);

    const ref = useRef<T>(value);

    useEffect(() => {
        ref.current = value;
    }, [value]);

    return [value, setValue, ref];
};
